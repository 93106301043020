import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LumIconSize } from '@lum-types';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lum-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass]
})
export class SpinnerComponent implements OnInit {
  @Input() size: LumIconSize = 24;

  constructor() {}

  ngOnInit(): void {}

  public getWrapperClasses(): string[] {
    const classes = [];

    classes.push('size-' + this.size, 'overflow-hidden');

    return classes;
  }
}
