import { DOCUMENT, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { IconsRegistry } from '@lum-services';
import { LumIcon } from '@lum-types';
import { LumLogger } from '@lum-utils';

@Component({
  selector: 'lum-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class IconComponent {
  private svgIcon?: SVGElement;
  @Input() size?: number = 24;
  @ViewChild('iconContainer', { static: true })
  iconContainer?: ElementRef<HTMLElement>;

  @Input()
  set name(iconName: LumIcon) {
    setTimeout(() => {
      if (this.svgIcon) {
        this.iconContainer?.nativeElement.removeChild(this.svgIcon);
      }

      const svgData = this.iconRegistry.getIcon(iconName);

      if (!svgData) {
        LumLogger.warn(`no svg for "${iconName}"`);
        return;
      }

      setTimeout(() => {
        this.svgIcon = this.svgElementFromString(svgData);
        this.iconContainer?.nativeElement.appendChild(this.svgIcon);
      }, 0);
    }, 0);
  }

  constructor(
    private element: ElementRef,
    private iconRegistry: IconsRegistry,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
  public getIconSizeClasses(): string[] {
    const classes = [];
    switch (this.size) {
      case 16:
        classes.push('w-4', 'h-4');
        break;
      case 24:
        classes.push('w-6', 'h-6');
        break;
    }

    return classes;
  }
}
