/* eslint-disable sonarjs/no-duplicate-string */
import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import {
  LumButtonIconPos,
  LumButtonSize,
  LumButtonVariant,
  LumIcon,
  LumIconSize,
  LumTheme,
} from '@lum-types';
import { neverError } from '../../helpers/never.helper';
import { IconComponent } from '../icon/icon.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[lum-button], a[lum-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [NgClass, NgIf, IconComponent, SpinnerComponent],
})
export class ButtonComponent {
  @Input() variant: LumButtonVariant = 'primary';
  @Input() icon?: LumIcon;
  @Input() iconPos: LumButtonIconPos = 'left';
  @Input() iconSize: LumIconSize = 16;
  @Input() size: LumButtonSize = 'M';
  @Input() loading = false;
  @Input() theme: LumTheme = 'on-light';
  @Input() color = 'text-blue-500';

  @HostBinding() @Input() isDisabled = false;
  @HostBinding('class.is-elastic') @Input() elastic = false;
  @HostBinding('class.is-full') @Input() full = false;
  @HostBinding('attr.disabled') get disabled(): boolean | null {
    return this.isDisabled || null;
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      classes.push('text-gray-400');
    }

    if (this.variant !== 'icon-only' && this.variant !== 'icon-only-gray') {
      classes.push('w-full');
    }

    if (this.elastic) {
      classes.push('flex', 'sm:inline-flex', 'text-nowrap');
    } else if (this.full) {
      classes.push('w-full');
    } else {
      classes.push('inline-flex', 'text-nowrap');
    }

    return classes;
  }

  public getButtonClasses(): string[] {
    let classes = [
      'transition-colors',
      'duration-200',
      'ease-in-out',
      'rounded-full',
      'typo-label-sm',
    ];

    if (this.variant !== 'icon-only' && this.variant !== 'icon-only-gray') {
      classes.push('w-full');
    }

    if (this.elastic) {
      classes.push('flex', 'w-full', 'sm:inline-flex');
      if (this.variant === 'link') {
        classes.push('py-2.5', 'sm:py-0');
      }
    } else if (this.full) {
      classes.push('flex');
    } else {
      classes.push('inline-flex');
    }

    if (this.variant === 'primary' || this.variant === 'secondary') {
      classes.push('leading-none');
      switch (this.size) {
        case 'S':
          classes.push('px-3', 'py-2');
          break;

        case 'M':
          classes.push('px-4', 'py-2.5', 'h-9');
          break;

        default:
          throw neverError(this.size);
      }
    }

    switch (this.variant) {
      case 'primary':
        classes = this.setPrimaryClasses(classes);
        break;

      case 'secondary':
        classes = this.setSecondaryClasses(classes);
        break;

      case 'link':
        classes = this.setLinkClasses(classes);
        break;

      case 'icon-only':
        classes = this.setIconClasses(classes, true);
        break;

      case 'icon-only-gray':
        classes = this.setIconGrayClasses(classes);
        break;

      case 'a-link':
        classes = this.setALinkClasses(classes);
        break;

      default:
        throw neverError(this.variant);
    }

    return classes;
  }

  private setPrimaryClasses(classes: string[]): string[] {
    classes.push('bg-blue-500');
    if (!this.isDisabled) {
      classes.push('text-white', 'hover:bg-blue-600', 'active:bg-blue-700');
    } else {
      classes.push('bg-gray-100 border-gray-300 text-gray-400 border-[1.5px]');
    }

    return classes;
  }

  private setSecondaryClasses(classes: string[]): string[] {
    classes.push('border-[1.5px]', 'border', 'border-blue-500');

    if (!this.isDisabled) {
      classes.push(
        'bg-white',
        'text-blue-500',
        'hover:bg-blue-100',
        'active:bg-blue-100',
        'active:text-blue-700',
        'active:border-blue-700'
      );
    } else {
      classes.push('bg-gray-100 border-gray-300 text-gray-400');
    }

    return classes;
  }

  private setALinkClasses(classes: string[]): string[] {
    if (!this.isDisabled) {
      classes.push('underline', 'text-left', 'hover:no-underline');
    }

    return classes;
  }

  private setLinkClasses(classes: string[]): string[] {
    if (!this.isDisabled) {
      const textColor = this.theme === 'on-light' ? this.color : 'text-white';
      classes.push(textColor, 'hover:underline');
    }

    return classes;
  }

  private setIconClasses(
    classes: string[],
    addPrimaryClasses: boolean
  ): string[] {
    if (addPrimaryClasses) {
      classes = this.setPrimaryClasses(classes);
    }

    switch (this.size) {
      case 'S':
        classes.push('h-[36px] w-[36px]');
        break;

      case 'M':
        classes.push('h-[44px] w-[44px]');
        break;

      default:
        throw neverError(this.size);
    }

    if (this.isDisabled) {
      classes.push('');
    }

    return classes;
  }

  private setIconGrayClasses(classes: string[]): string[] {
    classes.push(
      'bg-transparent',
      'border-gray-200',
      'text-blue-700',
      'rounded-md',
      'border',
      'group-hover:border-blue-200',
      'group-hover:text-blue-500'
    );

    switch (this.size) {
      case 'S':
        classes.push('p-1.5');
        break;

      case 'M':
        classes.push('h-[44px] w-[44px]');
        break;

      default:
        throw neverError(this.size);
    }

    if (this.isDisabled) {
      classes.push('');
    }

    return classes;
  }

  public getContentClasses(): string[] {
    const classes = [];

    if (this.iconPos === 'right') {
      classes.push('flex-row-reverse');
    }

    if (this.size === 'S') {
      classes.push('text-sm');
    }

    if (this.variant !== 'a-link') {
      classes.push('text-left');
    }

    return classes;
  }

  public getIconClasses(): string[] {
    if (this.variant === 'icon-only' || this.variant === 'icon-only-gray') {
      return [];
    }

    const classes = [];

    if (this.iconPos === 'right') {
      classes.push('pl-1.5');
    } else {
      classes.push('pr-1.5');
    }

    return classes;
  }

  public isIconContainerVisible(): boolean {
    return !!this.icon || this.loading;
  }
}
