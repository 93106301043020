import {
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';

import { LUM_ENV } from './environments/environment';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeCS from '@angular/common/locales/cs';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CustomMissingTranslationHandler } from '@lum-helpers';
import { ApiAuthInterceptor } from '@lum-interceptors';
import { GlobalErrorHandlerModule } from '@lum-modules';
import { LUM_ENVIRONMENT } from '@lum-types';
import { ToastMessageComponent } from '@lum-ui/toast-message';
import { NdsModule } from '@netural/nds';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Amplify } from 'aws-amplify';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { LUM_APP_ROUTES } from './app/app.routing';

if (LUM_ENV.production) {
  enableProdMode();
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEN);
registerLocaleData(localeDE);
registerLocaleData(localeCS);

// set amplify configuration for authentication
Amplify.configure({ Auth: { Cognito: LUM_ENV.cognito } });

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(LUM_APP_ROUTES),
    importProvidersFrom(
      BrowserModule,
      MatSidenavModule,
      NdsModule.forRoot({
        basePath: 'designsystem',
        storyblok: {
          version: 'draft',
          accessToken: '0sykE697bZcfs5PQTlXtHwtt', // TODO: this is the nds boilerplate token. so replace with your own token
        },
        logLevel: 'TRACE',
      }),
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: CustomMissingTranslationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      ToastrModule.forRoot({
        timeOut: 5000,
        disableTimeOut: 'extendedTimeOut',
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right',
        toastComponent: ToastMessageComponent,
      }),
      GlobalErrorHandlerModule
    ),
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: LOCALE_ID, useValue: 'cs' },
    { provide: LUM_ENVIRONMENT, useValue: LUM_ENV },
    { provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true },
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
