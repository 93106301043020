import { Routes } from '@angular/router';
import { LUM_ENVIRONMENT_GUARD } from '@lum-guards';

export const LUM_APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./lumeso/lumeso.routes').then((m) => m.LUM_LUMESO_ROUTES),
  },
  {
    path: 'designsystem',
    loadChildren: () =>
      import('./designsystem-nds/designsystem-nds.routes').then(
        (m) => m.LUM_DESIGNSYSTEM_ROUTES
      ),
    canActivate: [LUM_ENVIRONMENT_GUARD],
  },
  // Wild Card Route for 404 request
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () =>
      import('./page-not-found/page-not-found.component').then(
        (c) => c.PageNotFoundComponent
      ),
  },
];
