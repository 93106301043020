import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LumDialogInput } from '@lum-types';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../ui/button/button.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'lum-dialog-info',
    templateUrl: './dialog-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogComponent,
        NgIf,
        ButtonComponent,
        TranslatePipe,
    ]
})
export class DialogInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LumDialogInput,
    public dialogRef: MatDialogRef<LumDialogInput>
  ) {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
